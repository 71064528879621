export const virtualGame = {
  name: 'virtualGame',
  path: '/virtualgame',
  meta: {
    layout: 'default',
    text: '가상게임',
    gnb: true,
    sub: true
  },
  children: [
    {
      name: 'virtualGameChildren',
      path: '/virtualGame',
      meta: {
        text: '가상게임',
        img: require('@/assets/img/menu19.png'),
        gnbSub: true,
        class: 'aug',
        game: true,
        gameName: 'kiron',
        gameCode: 102
      }
    }
  ]
}
